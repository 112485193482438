<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-card-text" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                          v-model.trim="survey.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="survey.description"
                          :counter="32"
                          clearable
                          label="Kurzbeschreibung"
                          outlined
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="survey.external.surveyUrl"
                          label="Externe URL zur Umfrage"
                          required
                          hint="Der Link zur externen Umfragen (z.B. Typeform, Microsoft Forms, Google Forms etc.) wird in einem neuen Fenster zur Beantwortung geöffnet."
                          persistent-hint
                          :rules="[rules.required, rules.link]"
                          outlined
                          prepend-inner-icon="mdi-link"
                        ></v-text-field>
                        <v-text-field
                          v-model.trim="survey.external.resultsrulesUrl"
                          label="Externe URL zu Umfrageergebnissen"
                          hint="Sollen Ergebnisse der Umfrage einsehbar sein, kann eine URL zur Ergebnisseite angezeigt werden."
                          persistent-hint
                          :rules="[rules.link]"
                          outlined
                          prepend-inner-icon="mdi-link"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field
                          v-model="survey.deadline.date"
                          type="date"
                          label="Datum der Fälligkeit"
                          hint="DD.MM.YYYY"
                          outlined
                          :rules="[rules.required]"
                          required
                          prepend-inner-icon="mdi-calendar-end"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-text-field
                          v-model="survey.deadline.time"
                          label="Zeit der Fälligkeit"
                          hint="HH:MM"
                          outlined
                          :rules="[rules.required]"
                          type="time"
                          prepend-inner-icon="mdi-clock-end"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="survey.participants.allowedUserGroups"
                          :items="userGroups"
                          disabled
                          chips
                          small-chips
                          deletable-chips
                          outlined
                          return-object
                          label="Benutzergruppen auswählen"
                          :rules="[rules.attendanceLimitation]"
                          item-text="title"
                          item-value="id"
                          multiple
                          clearable
                          hide-details="auto"
                          append-inner-icon="mdi-infinity"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="survey.participants.allowedPersons"
                          :items="membershipsListFiltered"
                          disabled
                          chips
                          small-chips
                          :rules="[rules.attendanceLimitation]"
                          outlined
                          deletable-chips
                          return-object
                          label="Personen auswählen"
                          item-text="displayName"
                          item-value="uid"
                          multiple
                          clearable
                          hide-details="auto"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
export default {
  name: "survey-editor",
  props: {
    survey: {},
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
        link: (value) =>
          !value ||
          /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
            value
          ) ||
          "Ungültiger Link",
      },
    };
  },
  model: {
    prop: "survey",
    event: "surveyChange",
  },
  computed: {
    surveyLocal: {
      get: function () {
        return this.survey;
      },
      set: function (value) {
        this.$emit("surveyChange", value);
      },
    },
    memberships() {
      return this.$store.state.admin.membershipsPublic;
    },
    userGroups() {
      const userGroups = this.$store.state.admin.userGroups.map((obj) => ({
        title: obj.title,
        id: obj.meta.id,
      }));
      userGroups.unshift({ title: "Alle", id: "all" });
      return userGroups;
    },
    membershipsListFiltered() {
      const input = this.memberships;
      const groupItems = (arr) => {
        return arr.reduce((a, c) => {
          if (typeof a[c.group.title] === "undefined") a[c.group.title] = [];
          a[c.group.title] = [...a[c.group.title], c];
          return a;
        }, {});
      };
      const step1 = groupItems(input);
      const insertHeaders = (obj) => {
        return Object.entries(obj).reduce((a, [key, vals], i, d) => {
          let r = [
            {
              header: key,
            },
            ...vals,
          ];
          // this is needed to have the exact same array
          // that you presented as desirable output:
          // no divider at the last place
          if (i < d.length - 1) {
            r = [
              ...r,
              {
                divider: true,
              },
            ];
          }
          a = [...a, ...r];
          return a;
        }, []);
      };
      const step2 = insertHeaders(step1);
      const step3 = step2.map((item) => {
        if (item.header) {
          // Skip headers and dividers
          return item;
        } else {
          // Extract displayName and uid from membership object
          return { displayName: item.user.displayName, uid: item.user.uid };
        }
      });

      return step3;
    },
  },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.items),function(survey){return _c('v-list-item',{key:survey.meta.id,attrs:{"to":_vm.link
        ? {
            name: 'my-organization-surveys-details',
            params: {
              organizationId: _vm.$route.params.organizationId,
              itemId: survey.meta.id,
            },
          }
        : null,"link":_vm.link,"dense":_vm.dense}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1 font-weight-medium mb-1"},[_vm._v(_vm._s(survey.title)+" ")]),_c('v-list-item-subtitle',[_c('div',{staticClass:"font-weight-normal"},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-card-text")]),_c('span',[_vm._v(_vm._s(survey.description))])],1),_c('div',[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-timer-sand-complete")]),_vm._v("Frist bis "+_vm._s(survey.deadlineDecoded)+" ")],1),(
            _vm.deadlineEndsSoon(survey.deadline) &&
            !_vm.deadlineIsExceeded(survey.deadline)
          )?_c('v-chip',{staticClass:"font-weight-medium red--text text--darken-4 mt-2",attrs:{"x-small":"","color":"red lighten-4"}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("mdi-alert-circle")]),_vm._v("Frist endet bald")],1):(_vm.deadlineIsExceeded(survey.deadline))?_c('v-chip',{staticClass:"font-weight-medium mt-2",attrs:{"x-small":""}},[_c('v-icon',{attrs:{"left":"","x-small":""}},[_vm._v("mdi-alert-circle")]),_vm._v("Frist abgelaufen")],1):_vm._e()],1)],1),(_vm.showLinkButton && survey.external.surveyUrl)?_c('v-list-item-action',[_c('v-btn',{staticClass:"mb-4 ml-4",attrs:{"depressed":"","href":survey.external.surveyUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-open-in-new")]),_vm._v("Öffnen")],1)],1):_c('v-list-item-action',[_c('v-btn',{attrs:{"color":"red darken-1","icon":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <NavigationBar titel="Umfragen" :actions="actions"></NavigationBar>
    <TippCard
      hintID="Meine_Organisation_Umfragen_1_V1"
      text="Umfragen werden hier zur Beantwortung angezeigt. Sofern verfügbar, können auch die Ergebnisse abgerufen werden."
    >
    </TippCard>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-chart-box" title="Alle Umfragen">
              </Subheader>
              <div class="mt-7"></div>
              <v-data-iterator
                :items="surveys"
                hide-default-footer
                sort-desc
                sort-by="deadline"
                no-data-text="Keine Umfragen zur Beantwortung vorhanden"
              >
                <template v-slot:default="props">
                  <v-card>
                    <v-list>
                      <survey-list-items
                        :items="props.items"
                        link
                      ></survey-list-items>
                    </v-list>
                  </v-card>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import SurveyListItems from "@/components/my-organization/surveys/SurveyListItems.vue";
import { MY_ORGANIZATION_SURVEYS_create } from "@/data/permission-types.js";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { GET_SURVEYS } from "@/store/action-types.js";

export default {
  name: "my-organization-surveys",
  components: {
    NavigationBar,
    Subheader,
    TippCard,
    SurveyListItems,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          permission: `${MY_ORGANIZATION_SURVEYS_create}`,
          icon: "mdi-plus-circle",
          actionStyle: "textBtn",
          function: this.createItem,
        },
      ],
    };
  },
  computed: {
    surveys() {
      return this.$store.state.myOrganization.surveys;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${MY_ORGANIZATION}${GET_SURVEYS}`, {
        organizationId: this.$route.params.organizationId,
        query: {
          onlyActive: false,
          onlyOwn: true,
          ownUserGroup: this.$store.state.organization.membership.group.id,
        },
      });
    },
    createItem() {
      this.$router.push({
        name: "my-organization-surveys-new",
        params: {
          organizationId: this.$route.params.organizationId,
        },
      });
    },
  },
};
</script>

<template>
  <div>
    <NavigationBar
      titel="Neue Umfrage anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="createItem"
      >
        <survey-editor v-model="survey" mode="new"></survey-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { Timestamp } from "@/firebase";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { CREATE_SURVEY } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SurveyEditor from "@/components/my-organization/surveys/SurveyEditor.vue";

export default {
  name: "my-organization-survey-new",
  components: {
    NavigationBar,
    SurveyEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.createItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      survey: {
        title: null,
        description: null,
        deadline: {
          date: null,
          time: null,
        },
        participants: {
          allowedParticipantsIds: ["all"], // currently always "all"
          allowedPersons: [], // currently always []
          allowedUserGroups: [
            {
              title: "Alle",
              id: "all",
            },
          ], // currently always [{ title: "Alle", id: "all"}]
        },
        external: {
          surveyUrl: null,
          resultsUrl: null,
        },
      },
    };
  },
  methods: {
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MY_ORGANIZATION}${CREATE_SURVEY}`, {
          organizationId: this.$route.params.organizationId,
          title: this.survey.title,
          description: this.survey.description,
          deadline: this.convertToTimestamp(
            this.survey.deadline.date,
            this.survey.deadline.time
          ),
          type: "external",
          participants: {
            allowedParticipantsIds:
              this.survey.participants.allowedParticipantsIds, // currently always "all"
            allowedPersons: this.survey.participants.allowedPersons, // currently always []
            allowedUserGroups: this.survey.participants.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
          },
          external: {
            surveyUrl: this.survey.external.surveyUrl,
            resultsUrl: this.survey.external.resultsUrl,
          },
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>

<style></style>

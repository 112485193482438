<template>
  <div>
    <v-list-item
      v-for="survey in items"
      :key="survey.meta.id"
      :to="
        link
          ? {
              name: 'my-organization-surveys-details',
              params: {
                organizationId: $route.params.organizationId,
                itemId: survey.meta.id,
              },
            }
          : null
      "
      :link="link"
      :dense="dense"
    >
      <v-list-item-content>
        <v-list-item-title class="subtitle-1 font-weight-medium mb-1"
          >{{ survey.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <div class="font-weight-normal">
            <v-icon small left>mdi-card-text</v-icon
            ><span>{{ survey.description }}</span>
          </div>
          <div>
            <v-icon small left>mdi-timer-sand-complete</v-icon>Frist bis
            {{ survey.deadlineDecoded }}
          </div>
          <v-chip
            v-if="
              deadlineEndsSoon(survey.deadline) &&
              !deadlineIsExceeded(survey.deadline)
            "
            x-small
            color="red lighten-4"
            class="font-weight-medium red--text text--darken-4 mt-2"
            ><v-icon left x-small>mdi-alert-circle</v-icon>Frist endet
            bald</v-chip
          >
          <v-chip
            v-else-if="deadlineIsExceeded(survey.deadline)"
            x-small
            class="font-weight-medium mt-2"
            ><v-icon left x-small>mdi-alert-circle</v-icon>Frist
            abgelaufen</v-chip
          >
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="showLinkButton && survey.external.surveyUrl">
        <v-btn
          class="mb-4 ml-4"
          depressed
          :href="survey.external.surveyUrl"
          target="_blank"
          ><v-icon left>mdi-open-in-new</v-icon>Öffnen</v-btn
        >
      </v-list-item-action>
      <v-list-item-action v-else>
        <v-btn color="red darken-1" icon>
          <v-icon large> mdi-chevron-right </v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
export default {
  name: "survey-list-items",
  props: {
    items: {
      type: Array,
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
      required: false,
    },
    link: {
      type: Boolean,
      default: false,
      required: false,
    },
    showLinkButton: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    deadlineEndsSoon(deadline) {
      // return true if deadline is in the next 48 hours
      const deadlineDate = deadline.toDate();
      const now = new Date();
      const diff = deadlineDate.getTime() - now.getTime();
      const diffInHours = diff / (1000 * 3600);
      return diffInHours < 48;
    },
    deadlineIsExceeded(deadline) {
      // return true if deadline is in the past
      const deadlineDate = deadline.toDate();
      const now = new Date();
      const diff = deadlineDate.getTime() - now.getTime();
      return diff < 0;
    },
  },
};
</script>

<template>
  <div>
    <NavigationBar
      titel="Details"
      :actions="actions"
      showBackBtn
    ></NavigationBar>
    <section>
      <template>
        <v-container>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <Subheader icon="mdi-card-text" title="Informationen"></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-title class="subheading">{{
                    survey.title
                  }}</v-card-title>
                  <!-- <v-card-subtitle
                    ><v-chip
                      x-small
                      color="red lighten-4"
                      class="font-weight-medium red--text text--darken-4 mr-2"
                      >Unbeantwortet</v-chip
                    ></v-card-subtitle
                  > -->
                  <v-card-text class="mt-n2">
                    <div class="font-weight-normal">
                      <v-icon small left>mdi-card-text</v-icon
                      >{{ survey.description }}
                    </div>
                    <div>
                      <v-icon small left>mdi-timer-sand-complete</v-icon>Frist
                      bis {{ timestampConverter(survey.deadline) }}
                    </div>
                  </v-card-text>
                  <v-card-action
                    ><v-btn
                      v-if="survey.external.surveyUrl"
                      class="mb-4 ml-4"
                      depressed
                      :href="survey.external.surveyUrl"
                      target="_blank"
                      ><v-icon left>mdi-open-in-new</v-icon>Umfrage
                      öffnen</v-btn
                    >
                    <v-btn
                      v-if="survey.external.resultsUrl"
                      class="mb-4 ml-4"
                      depressed
                      :href="survey.external.resultsUrl"
                      target="_blank"
                      ><v-icon left>mdi-open-in-new</v-icon>Ergebnisse
                      öffnen</v-btn
                    >
                    <!--   -->
                  </v-card-action>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>

    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { DELETE_SURVEY } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import {
  MY_ORGANIZATION_SURVEYS_update,
  MY_ORGANIZATION_SURVEYS_delete,
} from "@/data/permission-types.js";

export default {
  name: "my-organization-surveys-details",
  components: {
    NavigationBar,
    Subheader,
    SupportTools,
  },
  data() {
    return {
      actions: [
        {
          title: "Bearbeiten",
          permission: `${MY_ORGANIZATION_SURVEYS_update}`,
          icon: "mdi-pencil",
          actionStyle: "textBtn",
          function: this.editItem,
        },
        {
          title: "Löschen",
          permission: `${MY_ORGANIZATION_SURVEYS_delete}`,
          icon: "mdi-delete",
          actionStyle: "textBtn",
          function: this.deleteItem,
        },
        { divider: true },
        { actionStyle: "clipboardBtn" },
      ],

      survey: null,
    };
  },
  computed: {
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [{ title: "survey", data: this.survey }];
    },
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("surveys")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.survey = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    routeDetails(item) {
      this.$router.push({
        name: "my-organization-surveys-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    editItem() {
      this.$router.push({
        name: "my-organization-surveys-edit",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        },
      });
    },
    deleteItem() {
      var res = confirm(
        "Bist Du sicher, dass Du die Umfrage löschen möchtest?"
      );
      if (res == true) {
        this.$store.dispatch(`${MY_ORGANIZATION}${DELETE_SURVEY}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,
        });
      }
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "long",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>

<style></style>

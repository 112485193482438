<template>
  <div>
    <NavigationBar
      titel="Umfrage bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        v-model="helpers.formIsValid"
        ref="form"
        @submit.prevent="updateItem"
        class="col s8"
      >
        <survey-editor v-model="survey" mode="edit"></survey-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db, Timestamp } from "@/firebase";
import { MY_ORGANIZATION } from "@/store/modules.js";
import { UPDATE_SURVEY } from "@/store/action-types.js";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import SurveyEditor from "@/components/my-organization/surveys/SurveyEditor.vue";

export default {
  name: "my-organization-survey-edit",
  components: {
    NavigationBar,
    SurveyEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],

      helpers: {
        formIsValid: false,
      },
      survey: null,
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("surveys")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.survey = doc.data();
            this.survey.deadline.date = this.convertToDate(doc.data().deadline);
            this.survey.deadline.time = this.convertToTime(doc.data().deadline);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    updateItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.$store.dispatch(`${MY_ORGANIZATION}${UPDATE_SURVEY}`, {
          organizationId: this.$route.params.organizationId,
          itemId: this.$route.params.itemId,

          title: this.survey.title,
          description: this.survey.description,
          deadline: this.convertToTimestamp(
            this.survey.deadline.date,
            this.survey.deadline.time
          ),
          type: "external",
          participants: {
            allowedParticipantsIds:
              this.survey.participants.allowedParticipantsIds, // currently always "all"
            allowedPersons: this.survey.participants.allowedPersons, // currently always []
            allowedUserGroups: this.survey.participants.allowedUserGroups, // currently always [{ title: "Alle", id: "all"}]
          },
          external: {
            surveyUrl: this.survey.external.surveyUrl,
            resultsUrl: this.survey.external.resultsUrl,
          },
        });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
    convertToDate(date) {
      date = date.toDate();
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1 < 10 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
      );
    },
    convertToTime(time) {
      time = time.toDate();
      return (
        (time.getHours() < 10 ? "0" : "") +
        time.getHours() +
        ":" +
        (time.getMinutes() < 10 ? "0" : "") +
        time.getMinutes()
      );
    },
  },
};
</script>
